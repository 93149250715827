import React from 'react'
import {navigateTo} from 'gatsby-link'

import Layout from '../components/layout'
import JumboBackground from '../components/jumbo-background'
import CardBox from '../components/card-box'
import MiniCard from '../components/mini-card'

import Outside from '../images/outside_dark.jpg'
import Courthouse from '../images/courthouse.jpg'
import Tram from '../images/tram_relocation.jpg'


const ProjectsPage = ({location}) => {
	return (
    <Layout path={location.pathname}>

      <JumboBackground
        background={Outside}
			/>

      <CardBox
        title="Nelson Street, Abbotsford"
        subtitle={(
        <React.Fragment>
          A former single level warehouse, this site was transformed into a brilliant 'aged' two storey office space.
          <br/><br/>
          This is building No. 1 in the Rock Art Series, watch out for Building No. 2 as it comes to life just around the corner in Russell St!
        </React.Fragment>
        )}
        button="View photos"
        btnArrow
        btnOnClick={() => navigateTo('/nelson-street')}
			>
        <MiniCard
          video="ck43zJNpS-s"
          title="Myoora Motor House"
        >
          Clear span, structural steel frame designed to replicate the historic stables long since removed from this very special property.
        </MiniCard>
        <MiniCard
          image={Tram}
          title="Tram relocation"
        >
          The recent ballot of W Class Trams saw one of our clients take possession of this beauty!
          <br/><br/>
          Our involvement included Structural Design of new foundations, Relocation of the Tram from the Newport Yard to Nunawading, Civil works associated with making good the roadway adjacent. Seating removal, Asbestos removal & Flooring replacement.
          <br/><br/>
          Many thanks to all the staff at Bendigo Tramways for their invaluable advice, technical expertise and support during this project.
        </MiniCard>
        <MiniCard
          image={Courthouse}
          title="Former Essendon Courthouse"
        >
          Mt Alexander Rd, Moonee Ponds
          <br/><br/>
          After the tragedy of the fire that saw the beautiful Courthouse largely destroyed, our tradesmen had the privledge of carrying out the restoration of the roof and upper level windows to enable the building to be watertight once more.
          <br/><br/>
          We'll look on with admiration as another team of tradesmen now take on the internal restoration of this project.
        </MiniCard>
        <MiniCard
          video="rYKd5wf-cYQ"
          title="Monument Men"
        >
          Relocation of this monument allowed Vic Roads to widen the Princess Highway at Traralgon.
        </MiniCard>
      </CardBox>

    </Layout>
  )
}

export default ProjectsPage
